import { lazy } from 'react';

export * from './components';
export * from './hooks';
export * from './mocks';
export * from './redux';
export * from './styles';
export * from './utils';

export const LazyDriverRoleInformation = lazy(() =>
  import(/* webpackChunkName: "Invitation", webpackPrefetch: true */ '@features/permissions/components/DriverRoleInformation').then(
    ({ DriverRoleInformation }) => ({
      default: DriverRoleInformation,
    })
  )
);